@import url("https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&family=Signika:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;500;600;700;800&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
:root{
  /** Colors **/
  --jambolo-red: #dc3545;
  --jambolo-green: #35dc45;
  --jambolo-blue: #3545dc;
  --jambolo-red-2: #990011;
  /** Fonts **/
  --jambolo-body-font: 'Jost', Poppins, sans-serif;
  --jambolo-h-font: 'Jost', Poppins, sans-serif;
}
body {
  margin: 0;
  font-family: var(--jambolo-body-font);
  background-color: #fcfefc;
	overflow-x: hidden !important; /* Hide horizontal scrollbar */
}

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("SofiaProBold"),
    url("./fonts/SofiaProBold.ttf") format("truetype");
}

p {
  font-size: 16px;
}
h1,h2,h3,h4,h5,h6{
	font-family: var(--jambolo-body-font);
}
/* color */
.bg-opaque-white{
  background: #ffffff;
}
.text-rd {
  color: var(--jambolo-red-2);
}
.bg-rd, .btn-danger {
  background: var(--jambolo-red-2);

  color: #dc3545;
}
.bg-rd,
.btn-danger {
  background: #dc3545;
}

ul {
  padding-left: 0rem !important;
}
/* navbar */
.navbar{
  padding: 1rem 2.5%;
}
.navbar-brand {
  color: #fff;
  font-size: 39 !important;
}
.navbar.sticky{
  background: #ffffff;
  box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  transition: all ease-in .3s;
}
.navbar-brand img{
  width: 85px;
}
.navbar-brand .dark-logo{
  display:none;
}
.navbar-brand .main-logo{
  display:block;
}
.navbar.sticky .navbar-brand .dark-logo{
  display:block;
}
.navbar.sticky .navbar-brand .main-logo{
  display:none;
}
.nav-link{
  font-family: var(--jambolo-body-font);
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  transition: all ease-in .3s;
}
.nav-link.active, .nav-link:hover, .nav-link:focus{
  color: var(--jambolo-red);
  transition: all ease-in .3s;
}
.navbar.sticky .nav-link{
  color: #242424;
  transition: all ease-in .3s;
}
.navbar.sticky .nav-link.active, .navbar.sticky .nav-link:hover{
  color: var(--jambolo-red);
  transition: all ease-in .3s;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
   color: unset;
}
.navbar-light .navbar-nav .nav-link {
  color: unset;
}
.nav-item.nav-btn{
  background: #ffffff;
    margin: 0 0 0 20px;
    border-radius: 5px;
    transition: all ease-in .3s;
}
.nav-item.nav-btn:hover, .navbar.sticky .nav-item.nav-btn{
  background: var(--jambolo-red);
    transition: all ease-in .3s;
}
.navbar.sticky .nav-item.nav-btn:hover{
  background: #242424;
    transition: all ease-in .3s;
}
.navbar.sticky .nav-item.nav-btn:hover .nav-link, .nav-item.nav-btn:hover .nav-link{
  color: #ffffff;
  transition: all ease-in .3s;
}
.navbar.sticky .nav-item.nav-btn .nav-link{
  color:#ffffff;
    transition: all ease-in .3s;
}
.nav-item.nav-btn .nav-link{
  color:#242424;
}
.navbar .icon-item{
  line-height: 2.5rem;
}
.navbar.sticky .icon-item svg path{
  fill: #242424;
  transition: all ease-in .3s;
}
.header-section {
  background-image: url("../public/assets/images/background.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
.home-content-row{
	height: 100vh;
    background: rgb(0 0 0 /30%);
}
.home-banner-content{
	padding: 200px 0 10px
}
.section-text {
  margin-top: 0;
}
.section-text h1 {
  font-size: 50px;
  font-weight: 400;
  font-family: var(--jambolo-h-font);
}
.sub-header-text{
  font-size: 16px;
  font-weight: 500;
  color: #7f7f7f;
}
.section-form {
  margin-top: 50px;
}
.form-control {
  height: 50px;
    line-height: 50px;
    border: 0;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
}
.search-btn{
  box-shadow: none !important;
    border: none !important;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50px;
	margin-left: 5px;
}
.search-btn svg{
  padding: 10px 5px !important;
}
.artisan_category{
	margin-left: 10px !important;
}

.text_faint{
  /* border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-style: groove !important; */
  border: none !important;
}
.searchform-wrap{
  background: #fcfefc;

    padding: 8px;
    border-radius: 35px;
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
}
.searchform-wrap .form-control{
	font-family: var(--jambolo-body-font);
  background: transparent;
}
.searchform-wrap input.bd-r{
  border-right:1px solid #e1e2e3;
  margin-left:10px
}
.stat {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 100px;
}
.statItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
}
.howitworks {
  margin-top: 60px;
}
.howitworks h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
}
.howitworks-icon {
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center !important;
}
/*::-webkit-scrollbar {*/
/*  width: 5px;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background: gray;*/
/*  border-radius: 1px;*/
/*}*/
.section{
	padding: 80px 0;
}
.section-title{
	padding: 0;
	margin: 0 0 50px;
}
.section-title .title{
	font-size: 34px;
}
.section-title .subtitle{
	font-size: 16px;
	color: #8e8e8e;
}
/** CARD LIST **/
.list-item{
	position: relative;
	display:inline-block;
	width: 100%; 
	overflow: hidden;
	padding:0;
	margin: 0 0 30px;
	border:0;
	-webkit-transform: translateZ(0);
    box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
}
.list-item .list-image{
	width: 100%;
    height: 220px;
    overflow: hidden;
    display: inline-block;
    position: relative;
}
.list-item .list-image img{
	max-width: 100%;
	width: 100%;
}
.list-item .list-image .image-overlay{
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top:0;
	background: transparent;
	transition: all ease-in .3s;
}
.list-item:hover .list-image .image-overlay{
	background: rgb(0 0 0 / 25%);
	transition: all ease-in .3s;
}
.list-item .list-content{
	padding-top: 220px;
	top:0;
	left:0;
	position: absolute;
	display: inline-block;
	background: transparent;
	width: 100%;
	transition: all ease-in .3s;
}
.list-item .list-content .content-wrap{
	position: relative;
	display: inline-block;
	width: 100%;
    height: auto;
    overflow: hidden;
	transition: all ease-in .3s;
}
.list-item:hover .list-content .content-wrap{
	height: auto;
	margin-top: -30px;
	background: #ffffff;
	transition: all ease-in .3s;
}
.list-item .list-content p{
	margin:0 0 7px;
}
.list-item .list-content .list-title{
	font-size: 18px;
	font-weight: 500;
	padding: 15px 15px 0
}
.list-item .list-content .list-desc{
	font-size: 14px;
	padding: 0 15px 0;
	color: #8e8e8e;
}
.list-item .list-sector{
	width: auto;
	padding: 5px 10px;
	background: var(--jambolo-blue);
	color: #ffffff;
	position: absolute;
	right: 10px;
	top: 15px;
	font-size:12px;
	text-transform: uppercase;
	border-radius: 4px;
}
.list-item .list-content .pricing{
	position: absolute;
	top: 15px;
	right: 15px;
}
.list-item .list-meta{	
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0 15px 10px;	
	transition: all ease-in .3s;
}
.list-item .list-footer{
	padding: 0 0 5px;
    margin-top: 70px;
    border-top: 1px solid #f3f2f1;
    position: relative;
    left: 0;
    width: 100%;
    bottom: 0;
    background: #ffffff;
    transition: all ease-in .3s;
}

.list-item .list-meta .meta-item{
	display: inline-block;
	position: relative;
}
.list-item .list-meta .meta-item span{
	font-size: 15px;
}
.list-item .list-meta .meta-item:first-child{
	margin-right: 10px;
}
.list-item .list-meta .meta-item span svg path{
	stroke: #212121;
    fill: var(--jambolo-red-2);
}
.btn-list{
	box-shadow: none !important;
	outline: none;
	font-size:15px;
	transition: all ease-in .3s;
}
.btn-list:hover, .list-item:hover .brn-list{
	color: var(--jambolo-red);
	transition: all ease-in .3s;
}
.page-header{
	padding: 0;
}
.with-bg{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
.page-header-row{
	padding: 200px 0 120px;
	background: rgb(0 0 0 / 30%);
	margin: 0;
}

.aboutus {
  background-size: cover;
  height: 50vh;
}

.aboutus h2{
  font-family: var(--jambolo-h-font);
}
.bg-white{
	background: #ffffff;
}
.text-col{
	display:flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
}
.text-col-2{
	display:flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	padding: 30px;
}
.col-title{
	margin-bottom: 30px;
}
.team-member{
	position: relative;
	display: inline-block;
	width: 100%;
	margin: 0 0 30px;
	padding: 0;
}
.team-member  .team-image{
	width: 100%;
	padding: 0 50px;
	margin-bottom: 20px;
	overflow: hidden;
}
.team-member  .team-image img{
	max-width: 100%;
	width: 100%;
	border-radius: 50%;
}
.team-member .team-details{
	min-height: 100px;
}
.team-member .team-details .sm-wrap{
	visibility: hidden;
	z-index: 0;
	margin-top: -40px;
	opacity: 0;
	transition: all ease-in .3s;
}
.team-member:hover .team-details .sm-wrap{
	visibility: visible;
	z-index: 1;
	margin-top: 5px;
	opacity: 1;
	transition: all ease-in .3s;
}
.testimonial-item{
	width: 100%;
	padding: 30px 20px;
	margin: 0 15px;
	position: relative;
	display:inline-block;
	background: #ffffff;
	box-shadow: -2px 2px 6px 0 rgb(0 0 0 / 7%);
	border:1px solid #f2f3f4;
	border-radius: 5px;
	transition: all ease-in .3s;
}
.testimonial-item:hover{
	margin-top : -7px;
	box-shadow: 2px -3px 12px 0 rgb(0 0 0 / 14%);
	transition: all ease-in .3s;
}
.testimonial-item:hover .icon svg,
.testimonial-item:hover .name,
.testimonial-item:hover .role{
	color: var(--jambolo-red-2);
	transition: all ease-in .3s;
}
.testimonial-item:hover .testi{
	color: #a0a0a0;
	transition: all ease-in .3s;
}
.as-seen > div > svg{
	color: #dfdfdf;
	opacity: 0.4;
	cursor: pointer;
	transition: all ease-in .3s;
}
.as-seen > div > svg:hover{
	color: #dfdfdf;
	opacity: 0.98;
	transition: all ease-in .3s;
}
footer {
	bottom: 0;
  background-color: #29323c;
  width: 100% !important;
  padding: 100px 100px 0px 100px;
}

footer h6 {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
}

footer p {
	font-weight: 400;
    font-size: 15px;
    line-height: 21px;
	color: #e8e8e8;
}

footer ul {
  list-style: none;
  /* padding: 50px 30px; */
  margin-top: 0px;
  padding-top: 0px;
}

footer li {
  padding: 8px;
}

footer ul li a {
  text-decoration: none;
  color: #f1f1f1;
}

footer ul li a:hover {
  color: #f32013;
}
.footer-logo{
	margin-bottom: 25px !important;
}
footer .phone-numbers{
	font-size: 14px;
}
footer .social-icons .item{
	color: #ffffff;
	margin-right: 10px;
}
footer .social-icons .item a{
	color:#ffffff;
	transition: all ease-in .3s;
}
footer .social-icons .item a:hover{
	color:var(--jambolo-red);
	transition: all ease-in .3s;
}
footer .widget h5{
	margin-bottom: 30px;
}

.footer-border {
  border: none;
}
footer .copyright{
	margin-top: 30px;
	padding-top: 20px;
	border-top:1px solid #6c757d
}


/* //scroll to top */
.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: #1f1f1f;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #f32013;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #000000;
  color: #f32013;
  border: 2px solid #19181a;
}

.mtt-1rm{
	margin-top: 1rem !important;
}
.mtt-2rm{
	margin-top: 2rem !important;
}
.mtt-25rm{
	margin-top: 2.5rem !important;
}

.pd-5{
	padding: 5px !important;
}
.pd-10{
	padding: 10px !important;
}
.pd-15{
	padding: 55px !important;
}

.mb-5{
	margin-bottom: 5px !important;
}
.mb-10{
	margin-bottom: 10px !important;
}
.mb-15{
	margin-bottom: 55px !important;
}

.th{
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px !important;
	height: 50px !important;
}

.th, .td, .tr {
	padding: 20px 10px !important;
	text-align: left !important;
}

.tr td {
	text-align: left !important;
	font-size: 16px !important;
	line-height: 18px !important;
//width: 100%;
}

.table-popup{
	padding: 24px;
	gap: 40px;
	position: relative;
//width: 548px;
	background: #FFFFFF;
	border-radius: 12px;
}

.table-popup header{
	padding-bottom: 10px;
	border-bottom: 0.5px solid rgb(240, 240, 240);
}

.modal-div {
	position: fixed; /* Sit on top of the page content */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7); /* #333333 background with opacity */
	z-index: 1110; /* Specify a stack order in case you're using a different order for other elements */
}

.modal-box-container {
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}


@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.ml-20{
	margin-left: 20px !important;
}

.form-control{
	border: 0px solid grey !important;
	border-radius: 4px !important;
}

input:focus{
	outline: none !important;
	border-color: #dc3545 !important;
}

/*.form-control:focus{*/
/*	border: #dc3545 !important;*/

/*}*/

.offcanvas-header {
	padding: 1rem 1rem 1rem 0 !important;
}

.offcanvas-body-style {
	 flex-grow: 1;
	padding: 1rem 1rem 1rem 0;
	 overflow-y: auto;
}
.card{
	border: 1px solid #f0f0f0 !important;
	/* border-radius: 12px !important; */
}
.active{
	/* background: #FFFFFF; */
	height: 45px;
	display: flex;
	align-items: center;
	border-radius: 0px 32px 32px 0px;
}

.active-sidebar{
	background: #FFFFFF;
	height: 45px;
	display: flex;
	align-items: center;
	border-radius: 0px 32px 32px 0px;
}

/*.style-spin{*/
/*	margin-top: -30px !important;*/
/*}*/

.searchform-wrap input{
	margin-left: 10px !important;
}

.width-25{
	width: 25% !important;
}

.width-50{
	width: 50% !important;
}

.select-search-field{
	width: 25% !important;
	border-top: none !important;
	border-right: 1px solid rgb(225, 226, 227) !important;
	border-bottom: none !important;
	border-left: none !important;
	border-image: initial !important;
	background: white !important;
}

.modal-div {
	position: fixed; /* Sit on top of the page content */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7); /* #333333 background with opacity */
	z-index: 1110; /* Specify a stack order in case you're using a different order for other elements */
}

.modal-box-container {
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.table-popup{
	padding: 24px;
	gap: 40px;
	position: relative;
	width: 22%;
	background: #FFFFFF;
	border-radius: 12px;
}


